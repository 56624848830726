:root // global wish to share with components

    {
    /* Colors */
    --login-btn: #442f50;
    --active-color:#FFA047;
    --border-color: #FFA047;
    --text-color: #F1EAF6;
    --mat-bg: #E0101D;

    --bright-bg: #442F50;
    --promo-btn-bg:#442F50;
    --tournament-menu-bg:#442f50;
    --tournament-card-disable:#442F50;
    --disable-text-color:#442F50;

    --card-theme-bg:#170A1F;
    --tab-bg:#24162C;
    --tag-bg:#24162C;
    --icon-btn:#442F50;
    --icon-btn-hover:#321E3D;
    --border-bg-popup:#442f50;

    --active-tab-bg:#321E3D;
    --dark-bg:#170A1F;
    --signup-btn: #FF7F50;
    --border-dark: #FFA047;
    --color-dark: #ff7f50;

    --btn-primary-hover:#006ADD;
    --btn-primary-active:#442F50c7;
    // --btn-primary-hover:#321E3D;
    // --btn-primary-active:#5352ed85;
    --btn-warn-hover: #5352ED ;
    --btn-accent-hover:#D96D45;
    --btn-accent-active:#ff7f50e6;
    --btn-orange-hover:#D96D45;
    --btn-orange-active:#ff7f50e6;


    --btn-hover-bg:#FF9533;

    --table-bg:#24162c;
    --light-grey : #dadada;

    --anchor-color:#FFE5AA;
    
    --a-tag-text-color:#FFA047;
    --a-tag-hover-color:#FFA047;

    // --btn-text-color: #1B2827;
    --table-odd-bg:#24162C;
    --box-bg: #24162C;
    --btn-hover: #321E3D;
    --btn-active-bg:#321E3D;
    --btn-filled-hover: #006ADD;
    --btn-empty-hover: #696050;
    --table-new-bg:#1B4B48;

    --table-new-bg:#170A1F;
    --new-border:#170A1F;
    --box-bg-menu:#170A1F;

    --text-light-grey: #F1EAF6;
    --text-color-white:#ffffff;
    --btn-text-color: #ffffff;
    --header-bg: #170A1F;
    --body-bg:#170A1F;
    --sidenav-bg: #170A1F;
    --top-menu-bg: #442F50;
    --table-border:#FFA047;
    
    --title-head-bg: #321E3D;
    --content-bg: #442f50;
    --divider-border: #FFA047;
    --popup-bg: #170a1f;
    --sidenav-hover: #321E3D;

    --soft-text:#F1EAF6;

    --main-bg: #170A1F;
    --main-color: #170A1F;
    --dark-main: #170A1F;
    --list-item-bg: #442f50;

    --table-head-bg : #2F615D;

    --table-head-bg : rgba(30, 144, 255, 0.25);

    // --tab-bg: #1D406A;

    --dark-table-bg: #170A1F;

    --soft-dark:#321E3D;
    --light:#442F50;
    
    //border-radius
    --default-radius:8px;
    --radius-12:12px;

    // desktop screen size
    --desktop-width: 1248px;

    //gloabl font-family
    --global-font: 'Lato';
    --category-font : 'digitalt';

    --global-bg: #170A1F;
    --progress-bar-color:#ff7f50;
    --verified-bg:linear-gradient(#FF9F47, #E0101D);

    --gradient:linear-gradient(#FF9F47, #E0101D);
    --gradient-hover:linear-gradient(#f88b26, #bc0b16);
    --gradient-border:linear-gradient(to bottom, #FF9F47 50%, #E0101D);
}
